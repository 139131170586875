import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPLeilani: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Leilani?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_lel.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Leilani?</h1>
          <h2>
            A dedicated guide for Leilani that will help you decide whether to
            pull her or not.
          </h2>
          <p>
            Last updated: <strong>03/Apr/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                If you care about the Corrosion team at all, yes, you should
                definitely get Leilani. She remains a strong main carry for the
                foreseeable future, and although Caroline is arguably the best
                Corrosion Leader for now, Leilani doesn’t stay far behind in
                that role either. Plus, Solbyrd is right around the corner, and
                it won’t take long for Long to arrive after that (no pun
                intended). What makes Leilani so great for the Corrosion team is
                her ability to trigger Corrosion Blasts with her combo, thus
                partially eliminating the random or micro-managing factor from
                the team and making this element more suitable for speedruns
                than before.
              </p>
            </div>
            <StaticImage
              src="../../../images/ash/generic/lel_1.webp"
              alt="Guides"
            />
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                Leilani is a rare case of a Skirmisher who not only remains
                useful after spending all her combos, but she also benefits from
                that. Thanks to the passive of her second skill, “String
                Shadow”, after her first skill runs out of uses, she gains 100%
                ATK SPD in addition to the splash auto-attack every few seconds.
              </p>
              <p>
                Going back to the first skill for a moment, “Moon Patrol”’s main
                utility is to trigger a Corrosion Blast, allowing the player to
                better control the flow of the corrosion team. Since it creates
                a Burst Window, it’s advised to use “Moon Patrol” in tandem with
                Leilani’s own Seed Skill, “Arrow of Promise”, and other
                abilities who rely on burst windows and intensify them at the
                same time, such as Lorelle’s Seed skill, “Night-long Aria”. Long
                story short, one possible play is: Fill Corrosion Gauge -&gt;
                Night-long Aria -&gt; Moon Patrol -&gt; Arrow of Promise -&gt;
                String Shadow.
              </p>
              <p>
                The logic behind this order for skill uses, is to maximize quick
                filling Corrosion Blasts. Lorelle’s “Night-long Aria” records
                all DMG dealt by allies and converts a percentage of the
                recorded amount into True Damage at the end of the casting.
                Then, Leilani comes in, with “Moon Patrol” triggering the
                Corrosion Blast, followed by her ultimate, “Arrow of Promise”,
                which in addition to the burst damage, also triggers the passive
                from “String Shadow”, greatly increasing Leilani’s attack speed
                and converting her attacks into AoE. Finally, skills like the
                active version of “String Shadow” or any other from Leilani’s
                teammates further enhance this deadly combo. The same logic can
                still be applied to any team where Leilani is placed, and will
                also synergize well with the mechanics added later by Solbyrd
                and Long.
              </p>
              <p>
                Leilani is all about seizing control of her destiny. She has the
                right tools to control exactly when she wants to trigger Blasts
                or her passive. However, her biggest strength is also her
                biggest downside. By being so focused on controlling her own
                mechanics and those of her element, she binds herself to the
                path of Corrosion, and becoming lackluster when placed in any
                other team. This is the path she chose, and the one she’ll have
                to stick to if she wishes to make use of all her potential.
                Despite all this, she’s still a viable backseat pick if you need
                an anti-air Echomancer or a duo-carry, but we have plenty of
                options for that.
              </p>
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Self-reliant kit, can decide when to trigger passives
                          and mechanics;
                        </li>
                        <li>
                          Doesn’t become semi-useless after running out of skill
                          uses, instead gaining an ATK SPD buff;
                        </li>
                        <li>
                          High burst damage but can also work well in long
                          battles.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Not very versatile;</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>Dupes</h5>
              <p>
                For casual players, Leilani functions perfectly fine with zero
                dupes. Her first dupe, however, is a great cost-benefit and
                worth getting if you have the resources. Her third dupe is
                highly recommended if you main the Corrosion team and want to
                use it for speedruns and higher difficulty content.
              </p>
              <h5>General Tips</h5>
              <ul>
                <li>
                  “String Shadow” can be used to interrupt most enemy actions
                  and buy some time before they close in, thanks to its knock
                  back and the way its AoE hits the targets.
                </li>
                <li>
                  “Moon Patrol” is a great tool to kite enemies, allowing
                  Leilani to move freely twice.
                </li>
                <li>
                  Prioritize dumping “Moon Patrol” early when dealing with
                  bosses. It deals additional damage based on the enemy’s HP,
                  with the output increasing the higher their current HP%. If
                  you have dupe 3 Leilani, this synergizes well with that
                  passive, gradually increasing her damage as the target’s HP%
                  goes down.
                </li>
              </ul>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  Skill 1, “Moon Patrol” is the priority. It’s her main source
                  of damage (aside from her fast ‘n furious auto-attacks).
                  Besides, “String Shadow”’s bonuses from the passive don’t
                  increase with the skill level.
                </li>
              </ul>
              <StaticImage
                src="../../../images/ash/generic/lel_2.webp"
                alt="Guides"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <p>
                NOTE: Remember you can mix and match Echomancers among all
                suggestions and alternate options, adjusting based on who you
                have available.
              </p>
              <h5>Team 1</h5>
              <p>
                Leader: <strong>Caroline (or Leilani)</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>FORGET ME NOT</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="caroline"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="leilani"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                The Corrosion team is very simple and straightforward to play.
                With the addition of Leilani, you can now trigger a Corrosion
                Blast whenever you want instead of relying on randomness or
                convoluted micromanagement. If you wish to optimize this team,
                however, there are a few extra steps. To charge Inspire stacks
                faster, you can bring Hassel or Sephonis (Secret Melody) or
                Longqing (Nightmare, Born from Ashes) and swap them out
                afterwards. Another option, particularly good when dealing with
                bosses, is Thea with “Curiosity of a Hailinian” in order to
                charge inspire stacks faster and place a Fire Zone. Then, use
                Begonia to trigger Melt and add ASPD buffs and swap them out.
                You can replace Thea if your build requires a faster Melt
                stacking instead of Inspire stacking. In that case, use Cyros
                dupe 1 as preferred option or Reid.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="thea"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="longqing"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="hassel"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="sephonis"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="gina"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="begonia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="aceo"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                (And anyone of those elements and roles) .
              </p>
              <p>
                CORE NEXUS SKILLS: Devotion, Bonus Time, Winning Chase, Starry
                Trail or Class Link (Triangle)
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Born From Ashes" mode="data" onProfile /> or{' '}
                  <AshTrace
                    name="Curiosity of a Hailinian"
                    mode="data"
                    onProfile
                  />{' '}
                  (if using Thea)
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile /> or{' '}
                  <AshTrace name="Nightmare" mode="data" onProfile /> (If using
                  Longqing)
                </li>
                <li>
                  <AshTrace name="Secret Melody" mode="data" onProfile /> or{' '}
                  <AshTrace name="The Weight of Life" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Invisible Mirror" mode="data" onProfile />
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="The Weight of Life" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="My Vision is Clear!" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Illusion of Flowers" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 2</h5>
              <p>
                Leader: <strong>Caroline (or Leilani)</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>LONELY IRIS</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="caroline"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="leilani"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="yuqi"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="changyao"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                This is a budget option for those who only have Leilani as their
                six-star or don’t have a Corrosion team built but want to play
                around with it and decide to borrow her. Caroline can be swapped
                for any other DPS or Support, depending on your needs. In that
                case, Leilani is favored as the leader. The idea here is to
                capitalize on the damage bonus from “My Vision is Clear” to keep
                Leilani as strong as possible even without the rest of the
                Corrosion team. Simply create a water zone with Changyao, place
                Yuqi there and have fun. If you have or can borrow Furray &
                Furney or Uni, that’s even better for water zone placement, and
                they can be swapped later for a stronger support.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="su-xiao"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="longqing"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="furay-and-furney"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="uni"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="gina"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="sephonis"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="mars"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                (And anyone of those elements and roles).
              </p>
              <p>
                CORE NEXUS SKILLS: Devotion, Bonus Time, Winning Chase, Starry
                Trail or Class Link (Triangle)
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace
                    name="Beneath the Deception"
                    mode="data"
                    onProfile
                  />{' '}
                  (Awakening skill lv 1 only)
                </li>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                  or
                  <AshTrace name="Hidden Blade" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="My Vision is Clear!" mode="data" onProfile />{' '}
                  (Core)
                </li>
                <li>
                  <AshTrace name="Secret Melody" mode="data" onProfile />
                  or
                  <AshTrace name="The Weight of Life" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Invisible Mirror" mode="data" onProfile />{' '}
                </li>
              </ul>
              <p>
                <strong>Alternative Memory Traces:</strong>
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshTrace name="Illusion of Flowers" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Lucky Find" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Yummiest Friend" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="solbyrd"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Solbyrd will temporarily take the Leader position of the
                Corrosion team away from Caroline after several months. A well
                built Solbyrd brings much needed speed to the team, and
                transforms it into a spam of ultimates and explosive blasts.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="long"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                The first support to truly rival Freda, Long is the current
                Leader of the Corrosion team in the CN version. She adds even
                more buffs to the table, completing the triad of ATK SPD
                (Leilani), Bonus DMG (Solbyrd) and Critical (Long). Her healing
                and support style are very aggressive, perfect for the chaotic
                and expansive game play style that the Corrosion team took in.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPLeilani;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Leilani | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Leilani that will help you decide whether to pull her or not."
    game="ash"
  />
);
